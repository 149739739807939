import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["template", "layersContainer", "source", "layer", "canvas", "width", "height"];
  connect() {
    console.log("conn");
    // this.setWidthHeight(null);
  }
  setWidthHeight(e) {
    console.log("setting w he");
    this.canvasTarget.width = this.widthTarget.value;
    this.canvasTarget.height = this.heightTarget.value;

    this.scaleCanvas();
  }

  scaleCanvas() {
    var con = this.canvasTarget.parentNode,
        canvas = this.canvasTarget,
        aspect = canvas.height/canvas.width,    
        width = con.offsetWidth,
        height = con.offsetHeight;
    console.log(width);
    console.log(Math.round(width * aspect));
    canvas.style.width = `${width}px`;
    canvas.style.height = `${Math.round(width * aspect)}px`;
  }

  addLayer(e) {
    //Toggle the other layers
    this.layerTargets.forEach((layer) => {
      let ctrl = this.toggleController(layer)
      ctrl.hide(e);
    });
    let new_index = new Date().getTime();
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new_index);
    this.layersContainerTarget.insertAdjacentHTML('beforeend', content);
  }
  
  toggleController(layer) {
    return this.application.getControllerForElementAndIdentifier(layer, "toggle");
  }

  setSource(e) {
    let source = e.currentTarget.value
    this.sourceTargets.forEach(t => {
      let input = t.querySelector("input, select");
      if(t.dataset["source"] == source) {
        t.classList.remove("hidden");
        input.required = true;
      } else {
        t.classList.add("hidden");
        input.required = false;
      } 
    });
    if(source == "url_param") this.testTarget.classList.remove("hidden");
    else this.testTarget.classList.add("hidden"); 
  }
}