import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["source", "test", "index"];

  connect() {
    if(this.indexTarget.textContent == "0")
      this.indexTarget.textContent = this.composerController.layerTargets.length;
  }

  setSource(e) {
    let source = e.currentTarget.value;
    this.sourceTargets.forEach(t => {
      let input = t.querySelector("input, select");
      if(t.dataset["source"] == source) {
        t.classList.remove("hidden");
        input.required = true;
      } else {
        t.classList.add("hidden");
        input.required = false;
      } 
    });
    if(source == "url_param") this.testTarget.classList.remove("hidden");
    else this.testTarget.classList.add("hidden"); 
  }

  removeLayer(e) {
    e.preventDefault();
    let layer = e.currentTarget;
    if(confirm("Are you sure you want to delete this layer?")) {
      layer.closest(".composer-layer").remove();
    }
  }

  get composerController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("composer"), "steps--composer");
  }
}