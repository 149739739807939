import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["source", "test"];

  setSource(e) {
    let source = e.currentTarget.value
    this.sourceTargets.forEach(t => {
      let input = t.querySelector("input, select");
      if(t.dataset["source"] == source) {
        t.classList.remove("hidden");
        input.required = true;
      } else {
        t.classList.add("hidden");
        input.required = false;
      } 
    });
    if(source == "url_param") this.testTarget.classList.remove("hidden");
    else this.testTarget.classList.add("hidden"); 
  }
}