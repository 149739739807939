import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["mainMenu", "fields", "controlsContainer", "rulesConatiner", "validationMessage"]

    connect() {
      this.controlsWrapperClass = this.data.get("controlsWrapperClass")
    }
    getFields(key) {
        return this.fieldsTargets.find(f => f.dataset.key == key)
    }

    selectField(e) {
      e.preventDefault();
      let dropdown = e.target.parentNode.parentNode.parentNode.parentNode.parentNode;
      dropdown.querySelector(".prospect_import_field_mappings_mapped_field input").value = e.currentTarget.dataset.id
      dropdown.querySelector(".prospect_import_field_mappings_entity input").value = e.currentTarget.dataset.fieldtype
      dropdown.querySelector("button").innerText = e.currentTarget.innerText
      this.dropdownController(e.target.parentNode.parentNode.parentNode.parentNode).hide(e);
    }

    selectMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      let menu = e.currentTarget.closest(".dropdown-menu")
      this.controlsContainerTarget.insertAdjacentElement("beforeend", this.mainMenuTarget)
      menu.insertAdjacentElement("beforeend", this.getFields(e.currentTarget.dataset.key));
    }

    showMenu(event) {
      event.preventDefault();
      if(!event.currentTarget.classList.contains("disabled")) {
        //Display the controls
        event.currentTarget.parentNode.parentNode.querySelector(".dropdown-menu").replaceChildren(this.mainMenuTarget)
        window.setTimeout(function () {
            document.getElementById('filterFields').focus();
        }, 0);
      }
    }

    resetMenu(e) {
        this.moveControls(e.currentTarget.querySelector(".segment-controls"))
    }

    moveControls(controls) {
      if(controls) {
          this.controlsContainerTarget.insertAdjacentElement("beforeend", controls)
      }
    }

    dropdownController(elm) {
      return this.application.getControllerForElementAndIdentifier(elm, "dropdown");
    }
}