import { Controller } from "@hotwired/stimulus"
import GLightbox from 'glightbox';
export default class extends Controller {
  initialize() {
    this.lightbox = new GLightbox({openEffect: "none", closeEffect: "none"});
  }

  reload () {
    this.lightbox.reload();
  }
}
