import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"];

 
  copy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.linkTarget.dataset.value);
  }
  
}