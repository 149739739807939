import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["urlInput", "addressBar", "browser", "iframe", "toggle", "deviceInput", "loadModal", "dots"];

  initialize() {
  }

  connect() {
    window.addEventListener("message", this.messageHandler.bind(this));
    this.bindIframeUnLoad();
    this.wasNavigated = false;
    this.wasToggled = false;
    // this.loadModalTarget.style.height = `${this.iframeTarget.offsetHeight}px !important`;
    this.loadModalTarget.style.height = `${this.browserTarget.offsetHeight}px`;
    this.embedded = this.element.dataset["embed"] == "1";
  }

  messageHandler(e) {
    if(e.origin != window.location.origin) return;
    try{
      let data = JSON.parse(e.data);
      if(data.action == "closeModal") {
        this.modalController._close();
      }
    } catch(e) {
      console.log(e);
    }
  }

  bindIframeUnLoad() {
    this.iframeTarget.contentWindow.addEventListener('beforeunload', (e) => {
      this.openModal(e);
    });
  }

  handleToggle(e) {
    if(this.wasToggled == true) {
      this.wasToggled = false;
      this.iframeTarget.contentWindow.location.reload();
      this.wasNavigated = true;
    }
  }

  openModal(e) {
    this.modalController._open();
  }

  handleLoad(e) {
    this.wasNavigated = false;
    this.bindIframeUnLoad();
  }

  toggleModal(e) {
    this.modalController.toggle();
  }

  toggle(e) {
    e.stopPropagation();
    this.deviceInputTarget.value = this.deviceInputTarget.value == "desktop" ? "mobile" : "desktop";
    this.browserTarget.classList.toggle("md:max-w-sm");
    this.browserTarget.classList.toggle("max-w-[3072px]")
    this.modalController.modalTarget.classList.toggle("md:max-w-sm");
    this.modalController.modalTarget.classList.toggle("max-w-[3072px]");
    this.toggleTarget.classList.toggle("translate-x-0")
    this.toggleTarget.classList.toggle("translate-x-[28px]")
    if(this.embedded == true && this.deviceInputTarget.value == "mobile") {
      this.dotsTarget?.classList?.remove("flex");
      this.dotsTarget?.classList?.add("hidden");
      this.urlInputTarget?.classList?.remove("mr-5");
    } else {
      this.dotsTarget?.classList?.add("flex");
      this.dotsTarget?.classList?.remove("hidden");
      this.urlInputTarget?.classList?.add("mr-5");
    }
    // Submit the playform
    if(this.element.dataset.url.length > 0 && this.wasNavigated != true) {
      this.wasToggled = true;
      Rails.fire(document.querySelector("form"), 'submit')
    }
  }

  switchPage(e) {
    e.preventDefault();
    e.currentTarget.dataset.page
  }


  go(e) {
    if(e.keyCode == 13) {
      e.stopPropagation();
      let inputValue = e.currentTarget.value.trim();
      
      // Prepend https:// if no protocol is present
      if (!inputValue.match(/^https?:\/\//)) {
        inputValue = `https://${inputValue}`;
      }
      
      if(new URL(this.element.dataset.url).origin != new URL(inputValue).origin) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("url", inputValue);
        window.location.search = searchParams.toString();
      } else {
        this.goTo(`${this.element.dataset.host}/proxy?url=${inputValue}`);
      }
    } 
  }

  goTo(url) {
    this.iframeTarget.src = url;
  }

  get playFormController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector("form"), "playForm");
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "modal");
  }
}