import { Controller } from "@hotwired/stimulus"
import { replaceUrlParam } from "../utils/helpers"
export default class extends Controller {
  static targets = ["newLink"];

  setOrderId(e) {
    let orderId = e.currentTarget.dataset["orderId"];
    this.newLinkTargets.forEach(newLink => {
      newLink.href = replaceUrlParam(newLink.href, "order_id_position", orderId);
    });
  }
}