import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [];

  connect() {
    console.log("loaded");
  }

  toggleActive(e) {
    let border = e.currentTarget.parentElement.querySelector(".checkbox-border");
    border.classList.toggle("hidden");
  }
}