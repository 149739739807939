import { Popover } from "tailwindcss-stimulus-components"
export default class AppearancePopover extends Popover {

  connect() {
    super.connect();
  }
  show() {
    return super.show();
  }
  hide(e) {
    console.log("curr tar");
    // console.log(e.currentTarget);
    let elm = document.elementFromPoint(e.x, e.y)
    console.log(elm);
    if(this.checkParent(elm)) {
      // e.stopPropagation();
      e.preventDefault();
      return;
    }
    console.log(e);
    return super.hide(e);
  }
  checkParent(child) {
    let node = child.parentNode;

    // keep iterating unless null
    while (node != null) {
        if (node == this.element) {
            return true;
        }
     node = node.parentNode;
     }
   return false;
  }
}