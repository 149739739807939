import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "image", "emptyPreview", "loader", "imageLink", "container"];
 
  connect() {
    const ctrl = this;
    this.imageTarget.onload = function(e) {
      ctrl.lightboxController.reload();
    //Need to get a ref to the controller
      ctrl.loaderTarget.classList.add("hidden");
      ctrl.imageTarget.classList.remove("hidden");
      ctrl.imageLinkTarget.classList.remove("hidden");
    }
  }

  keyPress(e) {
    if(e.keyCode == 13){
      e.preventDefault();
      this.generatePreview(e);
    }
  }

  generatePreview(e) {
    this.imageLinkTarget.classList.add("hidden");
    this.imageTarget.classList.add("hidden");
    this.emptyPreviewTarget.classList.add("hidden");
    this.loaderTarget.classList.remove("hidden");
    let pms = {};
    this.inputTargets.forEach(function(input) {
      pms[input.name] = input.value;
    });
    let params = new URLSearchParams(pms);
    let url = `${this.element.dataset.mockUrl}?${params.toString()}`;
    this.imageTarget.src = "";
    this.imageTarget.src = url;
    this.imageLinkTarget.setAttribute("href", url);
  }

  get lightboxController() {
    return this.application.getControllerForElementAndIdentifier(this.containerTarget, "lightbox");
  }

  
  
}