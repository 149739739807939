import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "container"];

  add(e) {
    let new_index = new Date().getTime();
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new_index);
    this.containerTarget.insertAdjacentHTML('beforeend', content);
  }
}