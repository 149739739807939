import { Controller } from "@hotwired/stimulus"

import * as ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/solarized_light';
export default class extends Controller {
  static targets = [ "input", "editor" ]

  connect() {
    console.log(this.editorTargets);
    this.editorTargets.forEach((edTarget, idx) => {
      console.log(edTarget);
      let editor = ace.edit(edTarget);
      editor.getSession().setMode('ace/mode/json');
      editor.setTheme('ace/theme/github_light');
      let jsonDoc = JSON.parse(this.inputTargets[idx].value);
      console.log(jsonDoc);
      editor.setValue(JSON.stringify(jsonDoc, null, '\t'));
    });
  }

  submit() {
    this.editorTargets.forEach((edTarget, idx) => {
      let editor = ace.edit(edTarget);
      this.inputTargets[idx].value = editor.getValue();
    });
  }
}