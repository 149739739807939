// Style
// JS
import {TourGuideClient} from "@sjmc11/tourguidejs/src/Tour"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.name = this.element.dataset["name"];
  }

  connect() {
    const steps = [
      {
        title: `${this.name} Interactive Demo`,
        content: `This is a quick tour of the ${this.name} interactive demo.
                  This interactive demo enables you to visualize the ${this.name} app directly on your store.`
      },
      {
        title: "Your Store",
        content: `Your store will be displayed here with the ${this.name} app embedded`,
        target: "#play-modal, iframe"
      },
      {
        title: "Toggle each Use case and Feature.",
        content: `View the demo of use cases and features of ${this.name} directly in your store.`,
        target: "#use-case-controls"
      },
      {
        title: "Navigate Pages",
        content: `Navigate the supported pages of your store to view the demo of supported features on each page.`,
        target: "[data-target='play-form.pageSwitcher']"
      },
      {
        title: "Toggle Devices",
        content: `Switch between mobile and desktop previews of your store with the demo of ${this.name}.`,
        target: "#deviceSwitcher"
      }
    ]

    this.tg = new TourGuideClient({steps: steps})
    // this.tg.backdrop = false
    this.startTour();
  }
  startTour() {
    this.tg.start()
  }
}