import { Dropdown } from "tailwindcss-stimulus-components"

export default class TagsDropdown extends Dropdown {
  static targets = ["form"]

  connect() {
    super.connect();
    this.hasChanged = false;
  }

  hide(e) {
    super.hide(e);
    // if(this.hasChanged) this.formTarget.submit()
  }

  openValueChanged() {
    super.openValueChanged();
    if (!this.openValue && this.hasChanged) {
      if(this.hasFormTarget)  this.formTarget.submit();
      else if(this.element.dataset.formId) {
        document.getElementById(this.element.dataset.formId).submit();
      }
    }
  }

  setChanged(e) {
    console.log("ch");
    this.hasChanged = true;
  }
}