import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["styleInput"];

  connect() {
    this.frame = document.getElementById('play_browser');
    console.log('styles load!!!')
    this.styleInputTargets.forEach((input) => {
      input.value = input.defaultValue;
    });
  }

  handleChanged(e) {
    console.log("change!");

    if(e.currentTarget.type == "text") {
      this.textChange(e);
    } else {
      this.cssChange(e);
    }
  }

  textChange(e) {
    this.frame?.contentWindow?.postMessage(JSON.stringify({ action: "updateText", key: e.currentTarget.dataset.csskey, value: e.currentTarget.value }));
  }

  cssChange(e) {
    let prefix = '';
    if(e.currentTarget.dataset.cssprefix) prefix = e.currentTarget.dataset.cssprefix;
    let suffix = '';
    if(e.currentTarget.dataset.csssuffix) suffix = e.currentTarget.dataset.csssuffix;
    let val = e.currentTarget.value;
    if(val == "true") val = 1;
    else if (val == "false") val = 0;
    // if (document.getElementById(`${e.currentTarget.dataset.csskey}-preview`)) {
    //   document.getElementById(`${e.currentTarget.dataset.csskey}-preview`).style.backgroundColor = `${prefix}${val}${suffix}`
    // }
    this.messageStyle(`--demoground-${e.currentTarget.dataset.csskey}`, `${prefix}${val}${suffix}`);
  }

  messageStyle(key, value) {
    this.frame?.contentWindow?.postMessage(JSON.stringify({ action: "updateStyle", key: key, value: value }));

  }
}